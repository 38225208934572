<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="mts_no" placeholder="MTS No."/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select>  
        </CCol>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="destination_id"
            placeholder="Destination"
          > 
          </v-select>  
        </CCol>
        <!-- <CCol lg="2">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="dept_id"
            placeholder="Dept/Proj"
          > 
          </v-select>  
        </CCol>
         -->
        
      <!-- </CRow>
      <CRow> -->
        <CCol lg="3">
          <CInput v-model="asset_no" placeholder="Asset No."/>
        </CCol> 
        <CCol lg="3">
          <Datepicker v-model="transfer_date_from" input-class="form-control" placeholder="Transfer date from" />
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="transfer_date_to" input-class="form-control" placeholder="Transfer date to" />
        </CCol>
        <!-- <CCol lg="2">
        </CCol> -->
        <CCol lg="3" class=" mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow> 
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';
  import Swal from 'sweetalert2';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            mts_no: "",
            origin_id: "",
            destination_id: "",
            dept_id: "",
            transfer_date_from: moment(new Date()).subtract(6, 'month')._d,
            transfer_date_to: new Date(),
            asset_no: null, 
             
            customerList: {
              data: []
            }, 
          }
        },
      mounted() { 
        this.getCustomer(); 
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            mts_no: this.mts_no,
            origin_id: this.origin_id,
            destination_id: this.destination_id,
            dept_id: this.dept_id,
            transfer_date_from: this.transfer_date_from,
            transfer_date_to: this.transfer_date_to,
            asset_no: this.asset_no, 
          }
          if(moment(this.transfer_date_to).diff(moment(this.transfer_date_from), 'months', true) > 6){
              Swal.fire({
                title: 'Note!',
                text: "Transfer date range should not exceed 6 months.",
                icon: 'warning', 
              })
            }
            else{
              this.$emit("depot-search-query", data)
            } 
        },
        clearSearch() {
          this.mts_no = "";
          this.origin_id = "";
          this.destination_id = "";
          this.dept_id = "";
          this.transfer_date_from =  moment(new Date()).subtract(6, 'month')._d;
          this.transfer_date_to = new Date();
          this.asset_no = ""; 
   
          const data = {
            mts_no: this.mts_no,
            origin_id: this.origin_id,
            destination_id: this.destination_id,
            dept_id: this.dept_id,
            transfer_date_from: moment(new Date()).subtract(6, 'month')._d,
            transfer_date_to: new Date(),
            asset_no: this.asset_no, 
          }
          this.$emit("depot-search-query", data)
        }, 
        getCustomer(){

          axios.get(config.api_path+'/reference/customer-list', {
          params: {
            show_all : true
          }
          })
          .then(response => {
            this.customerList = response.data;
            
          })

          }, 
      // getCustomer(){

      //   axios.get(config.api_path+'/reference/customer-list')
      //   .then(response => {
      //     this.customerList = response.data; 
      //     this.customerList.data.unshift({
      //       customer_name: 'All',
      //       id: 'all'
      //     })
      //   })

      // }, 

      
      }
    }
</script>

<style scoped>

</style>
