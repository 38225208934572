<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md"  @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5><br>
				<CRow class="mb-2">
                    <CCol lg="12">
						<label class="mb-1">RMTS No.</label>
						<CInput v-model="filter.mts_no"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Origin</label>
                        <!-- <v-select 
                            label="setting_name" 
                            :options="origin_list.data"
                            :reduce="item => item.id"
                            v-model="filter.origin_id"> 
                        </v-select>   -->
                        <v-select 
                            label="customer_name" 
                            :options="origin_list.data"
                            :reduce="item => item.id"
                            v-model="filter.origin_id"> 
                        </v-select>  
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Destination</label>
                        <!-- <v-select 
                            label="setting_name" 
                            :options="destination_list.data"
                            :reduce="item => item.id"
                            v-model="filter.destination_id"> 
                        </v-select>   -->
                        <v-select 
                            label="customer_name" 
                            :options="destination_list.data"
                            :reduce="item => item.id"
                            v-model="filter.destination_id"> 
                        </v-select>  
					</CCol>
                    <CCol lg="12" class="mb-1">
						<label class="mb-1">Asset No.</label>
                        <CInput v-model="filter.asset_no"/>
					</CCol>
                  
                    <CCol lg="12" class="mb-3">
						<label class="mb-0">Transfer date from</label>
						<Datepicker v-model="filter.transfer_date_from" input-class="form-control"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Transfer date to</label>
                        <Datepicker v-model="filter.transfer_date_to" input-class="form-control"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
                        <h6 class="text-danger"> {{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  
import Datepicker from 'vuejs-datepicker';
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect, Datepicker },
    mounted() {
        
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
                mts_no: "",
	            origin_id: "",
	            destination_id: "",
	            dept_id: "",
	            transfer_date_from: moment(new Date()).subtract(6, 'month')._d,
	            transfer_date_to: new Date(),
	            asset_no: "", 
			},
            origin_list:[],
            destination_list:[],
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            this.report_downloaded = false;
            if(this.dataValidate() && this.dateFilterValidatePrompt()){
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;

                var transfer_date_from = this.filter.transfer_date_from;
                if(transfer_date_from != ""){
                    transfer_date_from = moment(transfer_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }

                var transfer_date_to = this.filter.transfer_date_to;
                if(transfer_date_to != ""){
                    transfer_date_to = moment(transfer_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }
                await axios.post(`${config.api_path}/reports/materialTransfer`, {
                    company_id:config.global_user_data.company_id,
                    mts_no:this.filter.mts_no,
                    transfer_date_from,
                    transfer_date_to,
                    origin_id:this.filter.origin_id,
                    destination_id:this.filter.destination_id,
                    asset_no:this.filter.asset_no
                // await axios.get(config.api_path+'/reports/materialTransfer?'+
                //     '&company_id='+config.global_user_data.company_id+
                //     '&mts_no='+this.filter.mts_no+
                //     '&transfer_date_from='+transfer_date_from+
                //     '&transfer_date_to='+transfer_date_to+
                //     '&origin_id='+this.filter.origin_id+
                //     '&destination_id='+ this.filter.destination_id+
                //     '&asset_no='+this.filter.asset_no)
            }).then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.error_msg = '';
                })
                .catch(err => {
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = 'Please select a filter before downloading the report.';
                this.dateFilterValidatePrompt();
            }
        },
        getOrigin(){
           
            // axios.get(config.api_path+'/reference/settings-list', {
            //     params:{ setting_type:'origin'}
            //  }) .then(response => {
            //     this.origin_list = response.data;
            // });


            axios.get(config.api_path+'/reference/customer-list', {
                params:{  show_all : true }
             }) .then(response => {
                this.origin_list = response.data;
            });
        },
        getDestination(){
            // axios.get(config.api_path+'/reference/settings-list', {
            //     params:{ setting_type:'destination'}
            //  }) .then(response => {
            //     this.destination_list = response.data;
            // });

            axios.get(config.api_path+'/reference/customer-list', {
                params:{ show_all : true }
             }) .then(response => {
                this.destination_list = response.data;
            });
        },
        clear(){
            this.filter.mts_no = null;
            this.filter.origin_id = null;
            this.filter.destination_id = null;
            this.filter.transfer_date_from = moment(new Date()).subtract(5, 'month')._d,
            this.filter.transfer_date_to = new Date(),
            this.filter.asset_no = null;
        },
        dataValidate(){
            return true;
            if(
                this.filter.mts_no || 
                this.filter.origin_id || 
                this.filter.destination_id ||
                this.filter.asset_no ||
                (this.filter.transfer_date_from && this.filter.transfer_date_to && this.dateRangeValidate(this.filter.transfer_date_from, this.filter.transfer_date_to)) 
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(f, t){
            let from = f;
            let to = t;
            if(!from&&!to){
                return true;
            }else{
                if(from && to){
                    const start = new Date(from);
                    const end = new Date(to);
                    const differenceInMilliseconds = end - start;
                    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                    return differenceInDays <= 6;
                }
                else{
                    return false;
                }
            }
        },
        dateFilterValidatePrompt(){
            let from = this.filter.transfer_date_from;
            let to = this.filter.transfer_date_to;
            let num_month = moment(to).diff(from, 'months');
            if(from && to){
                // const start = new Date(from);
                // const end = new Date(to);
                // const differenceInMilliseconds = end - start;
                // const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                // if(!(differenceInDays <= 6)){
                //     this.error_msg = 'Filter date should be 6 months only.';;
                //     return false;
                // }
                // else{
                //     return true;
                // }
                if(num_month > 6) {
                    this.error_msg = 'Filter date should be 6 months only.';;
                    return false;
                }

                return true;
            }
            else{
                return true
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;
            
            if(!this.origin_list?.data?.length)
                this.getOrigin();
            if(!this.destination_list?.data?.length)
                this.getDestination();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>
